import {GET_THEME,UPDATE_THEM} from "./themeActions";

const initState = {
    background: "#393e64",
    menuBackground: "#836ff2",
    name: "",
    logo: "",
    textColor:"#fff"
}

export const themeReducer = (state = initState, action) => {
    const {type, payload} = action;

    switch (type) {
        case GET_THEME:{
            window.localStorage.setItem("background",payload.background);
            window.localStorage.setItem("menuBackground",payload.menuBackground);
            window.localStorage.setItem("logo",payload.logo)
            return{
                ...state,
                ...payload
            }
        }
        case UPDATE_THEM:{
            window.localStorage.setItem("background",payload.background);
            window.localStorage.setItem("menuBackground",payload.menuBackground);
            window.localStorage.setItem("logo",payload.logo)
            return{
                ...state,
                ...payload
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}
