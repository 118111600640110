import React, {useState} from 'react';
import {Collapse, DialogContent, DialogTitle, List, ListItem, ListItemText, Typography} from "@material-ui/core";
import {useSelector} from "react-redux";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";

const TriggersGroupCollapse = ({name, children}) => {
    const [open, setOpen] = useState(false);
    return (
        <React.Fragment>
            <ListItem
                button
                onClick={() => setOpen((prevState) => !prevState)}
            >
                <ListItemText primary={name}/>
                {open ? <ExpandLess/> : <ExpandMore/>}
            </ListItem>
            <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
            >
                <List
                    component="div"
                    style={{paddingLeft: '16px'}}
                >
                    {children}
                </List>
            </Collapse>
        </React.Fragment>
    );
};


const AddCommandDialog = ({values,setFieldValue}) => {
    const programs = useSelector(({programs}) => programs.interface);
    const {enqueueSnackbar} = useSnackbar();
    const {t} = useTranslation();
    return (
        <React.Fragment>
            <DialogTitle>
                {t("calendar.form.commandList")}
            </DialogTitle>
            <DialogContent style={{height: "50vh"}}>
                {programs.map(group=>(
                    <TriggersGroupCollapse
                        key={group._id}
                        name={group.name}
                    >
                        {group.programs.map(program=>(
                            <ListItem
                                button
                                key={program._id}
                                onClick={() => {
                                    const isAdded = values.find(command=>command.commandName===program.name);
                                    if(isAdded) enqueueSnackbar(t("message.commExists"),{variant:"warning"})
                                    else setFieldValue("commands", [...values.map(command=>({...command})), {
                                        id:program._id,
                                        groupName: group.name,
                                        commandName: program.name,
                                        groupID:group._id
                                    }])
                                }}
                            >
                                <Typography>{program.name}</Typography>
                            </ListItem>
                        ))}
                    </TriggersGroupCollapse>
                ))}
            </DialogContent>
        </React.Fragment>
    );
};

export default AddCommandDialog;
