import React, {useState} from 'react';
import {Button, Dialog, Grid, List, ListItem, ListItemIcon, ListItemText, Typography} from "@material-ui/core";
import {AddCircle, Delete} from "@material-ui/icons";
import AddCommandDialog from "./AddCommandDialog";
import {useTranslation} from "react-i18next";

function CalendarCommandPicker({commands = [], setFieldValue}) {
    const [dialog,setCommandDialog] = useState(false);
    const {t} = useTranslation();
    return (
        <Grid container>
            <Grid item xs={12} sx={{mb: 1, mt: 1}}>
                <Typography
                    variant={"h6"}
                >
                    {t("calendar.form.commands")}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <List>
                    {commands.map(command => (
                        <ListItem
                            key={command.id}
                        >
                            <ListItemText
                                primary={command.commandName}
                                secondary={command.groupName}
                            />
                            <ListItemIcon style={{justifyContent:"center"}}>
                                <Delete
                                    fontSize={"medium"}
                                    onClick={()=>setFieldValue("commands", commands.filter(
                                        com => com.commandName !== command.commandName
                                        )
                                    )}
                                />
                            </ListItemIcon>

                        </ListItem>
                    ))}
                </List>
            </Grid>
            <Grid item xs={12}>
                <Button
                    color="primary"
                    size="large"
                    variant="outlined"
                    fullWidth
                    onClick={() => setCommandDialog(true)}
                >
                    <AddCircle/>
                </Button>
            </Grid>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={dialog}
                onClose={() => setCommandDialog(false)}
            >
                <AddCommandDialog values={commands} setFieldValue={setFieldValue}/>
            </Dialog>
        </Grid>
    );
}

export default CalendarCommandPicker;
