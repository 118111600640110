export const GET_THEME="GET_THEME"
export const UPDATE_THEM="UPDATE_THEME"


export const getCustomTheme=(payload)=>({
    type:GET_THEME,
    payload
})
export const updateCustomTheme = (payload)=>({
    type:UPDATE_THEM,
    payload
})
