import React from "react"
import {Backdrop, CircularProgress} from "@material-ui/core";


export const LoadingScreen = () => (
    <Backdrop
        open={true}
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}>
        <CircularProgress color="inherit"/>
    </Backdrop>)
