import React, {useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography
} from "@material-ui/core";
import {AddCircle} from "@material-ui/icons";
import moment from "moment";
import {useTranslation} from "react-i18next";

const handleToggle = (value, state, setState) => {
    if (state.includes(value)) {
        return setState("start_every", state.filter(min => min !== value))
    }
    return setState("start_every", [...state, value])
}

const minutes = Array.from({length: 60}, (value, index) => index)


const Every = ({
                   start_date,
                   end_date,
                   is_astro_start,
                   is_astro_end,
                   start_time,
                   end_time,
                   astro_start,
                   astro_start_offset,
                   astro_end,
                   astro_end_offset,
                   start_every = new Set([]),
                   setFieldValue
               }) => {
    const [isOpen, setOpenDial] = useState(false);
    start_time = moment(start_time, "HH:mm:ss");
    end_time = moment(end_time, "HH:mm:ss");

    const isDisAll = is_astro_start && is_astro_end ?
        astro_start === astro_end && astro_start_offset === astro_end_offset
        :
        end_time.get("minutes") - start_time.get("minutes") <= 2 &&
        !is_astro_end && !is_astro_start
    ;

    const inputText = [...start_every]
    const {t} = useTranslation();

    return (
        <Grid container justifyContent={"space-between"}>
            <Grid item xs={12}>
                <Grid container justifyContent={"space-between"}>
                    <Grid item>
                        <Typography
                            variant={"h6"}
                        >
                            {t("calendar.form.every")}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton disabled={moment(start_date).isSame(moment(end_date)) ? isDisAll : false}
                                    onClick={() => setOpenDial(true)} size={"small"} color="primary" component="span">
                            <AddCircle/>
                        </IconButton>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={12}>
                <Typography>
                    {`${inputText.map(minute => ` ${minute}`)} ${inputText.length > 1 ? t("calendar.form.minutes") : t("calendar.form.minute")}`}
                </Typography>
            </Grid>
            <Dialog open={isOpen} onClose={() => setOpenDial(false)}>
                <DialogTitle>
                    {t("calendar.form.every")}
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={1}>
                        {minutes.map((minute) => (
                            <Grid item xs={3} key={minute}>
                                <Button
                                    onClick={handleToggle.bind(null, minute, start_every, setFieldValue)}
                                    fullWidth variant={"contained"}
                                    color={inputText.includes(minute) ? "secondary" : "primary"}
                                >
                                    {minute}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => start_every.length > 0 ? setFieldValue("start_every", []) : setFieldValue("start_every", minutes)}
                        variant={"contained"} color={"secondary"}
                    >
                        {t("calendar.form.select_all")}
                    </Button>
                    <Button
                        onClick={() => setOpenDial(false)}
                        variant={"text"}>
                        {t("calendar.form.close")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default Every;
