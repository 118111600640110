import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider,
    Grid,
    IconButton, Tab, Tabs,
    Typography
} from "@material-ui/core";
import moment from "moment";
import {AddCircle} from "@material-ui/icons";
import {useTranslation} from "react-i18next";


const daysArr = new Array(31).fill(0).map((val, i) => i + 1);
const yearsArr = new Array(10).fill(2021).map((val, i) => val + i);
const hoursArr = new Array(24).fill(0).map((val, i) => i);

const sortFn = (a, b) => a === b ? 0 : a < b ? -1 : 1;

const selectAll = (tabIndex, setFieldValue, years, months, week_days, days, hours, monthsList, weeksList) => {

    switch (tabIndex) {
        case 0 : {
            if (years.length) {
                return setFieldValue("except_year", [])
            }
            return setFieldValue("except_year", yearsArr)
        }
        case 1 : {
            if (months.length) {
                return setFieldValue("except_month", [])
            }
            return setFieldValue("except_month", monthsList.map((v, i) => i))
        }
        case 2 : {
            if (week_days.length) {
                return setFieldValue("except_week_day", [])
            }
            return setFieldValue("except_week_day", weeksList.map((v, i) => i))
        }
        case 3 : {
            if (days.length) {
                return setFieldValue("except_day", [])
            }
            return setFieldValue("except_day", daysArr)
        }
        case 4 : {
            if (hours.length) {
                return setFieldValue("except_hour", [])
            }
            return setFieldValue("except_hour", hoursArr)
        }
        default:
            return
    }
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Grid
            container
            spacing={1}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                children
            )}
        </Grid>
    );
}

const handleToggle = (value, state, setState, fieldName) => {
    if (state.includes(value)) {
        return setState(fieldName, state.filter(min => min !== value))
    }
    return setState(fieldName, [...state, value])
}

const Except = ({
                    is_astro_start,
                    is_astro_end,
                    astro_start,
                    astro_start_offset,
                    astro_end_offset,
                    astro_end,
                    start_time,
                    end_time,
                    start_date,
                    end_date,
                    except_year = [],
                    except_month = [],
                    except_week_day = [],
                    except_day = [],
                    except_hour = [],
                    setFieldValue
                }) => {
    const [tabIndex, setTabIndex] = useState(4);
    const handleChange = (e, tabI) => {
        setTabIndex(tabI)
    }

    const {t,i18n} = useTranslation();
    const monthsNames = moment.months();
    let weekNames = moment.weekdays();
    weekNames.push(weekNames.shift());
    const [isOpen, setOpenDial] = useState(false);
    const years = except_year ? [...except_year].sort(sortFn) : [];
    const months = except_month ? [...except_month].sort(sortFn).map(monthNum => monthsNames[monthNum]) : [];
    const days = except_day ? [...except_day].sort(sortFn) : [];
    const week_days = except_week_day ? [...except_week_day].sort(sortFn).map(day => weekNames[day]) : [];
    const hours = except_hour ? [...except_hour].sort(sortFn) : [];
    start_date = moment(start_date);
    end_date = moment(end_date);
    start_time = moment(start_time, "HH:mm:ss");
    end_time = moment(end_time, "HH:mm:ss");



    const isDisYear = start_date.get("year") === end_date.get("year");
    const isDisMonth = isDisYear && start_date.get("month") === end_date.get("month");
    const isDisDay = isDisMonth && end_date.get("D") - start_date.get("D") <= 2;
    const isDisAll = isDisMonth && start_date.get("D") === end_date.get("D") && ((is_astro_start && is_astro_end) ?
        astro_start === astro_end && astro_start_offset === astro_end_offset : start_time.get("hour") - end_time.get("hour") <= 2 && !is_astro_end && !is_astro_start)

    useEffect(()=>{

    },[i18n.language])

    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container justifyContent={"space-between"}>
                    <Grid item>
                        <Typography
                            variant={"h6"}
                        >
                            {t("calendar.form.except")}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton disabled={isDisAll} onClick={() => setOpenDial(true)} size={"small"} color="primary"
                                    component="span">
                            <AddCircle/>
                        </IconButton>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={12}>
                {years.length ? (
                    <Typography variant={"subtitle1"}>
                        {`${t("calendar.form.year")}: ${years.map(year => ` ${year}`)}`}
                    </Typography>
                ) : null}
            </Grid>
            <Grid item xs={12}>
                {months.length ? (
                    <Typography>
                        {`${t("calendar.form.month")}: ${months.map(month => ` ${month}`)}`}
                    </Typography>
                ) : null}
            </Grid>
            <Grid item xs={12}>
                {days.length ? (
                    <Typography>
                        {`${t("calendar.form.day")}: ${days.map(day => ` ${day}`)}`}
                    </Typography>
                ) : null}
            </Grid>
            <Grid item xs={12}>
                {week_days.length ? (
                    <Typography>
                        {`${t("calendar.form.week_day")}: ${week_days.map(day => ` ${day}`)}`}
                    </Typography>
                ) : null}
            </Grid>
            <Grid item xs={12}>
                {hours.length ? (
                    <Typography>
                        {`${t("calendar.form.hour")}: ${hours.map(hour => ` ${hour}`)}`}
                    </Typography>
                ) : null}
            </Grid>
            <Dialog open={isOpen} onClose={() => setOpenDial(false)} fullWidth maxWidth={"md"}>
                <DialogTitle>
                    {t("calendar.form.except")}
                </DialogTitle>
                <DialogContent dividers style={{minHeight: "60vh"}}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Tabs
                                variant="scrollable"
                                scrollButtons="auto"
                                value={tabIndex}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                aria-label="basic tabs example">
                                <Tab label={t("calendar.form.year")} {...a11yProps(0)} disabled={isDisYear}/>
                                <Tab label={t("calendar.form.month")} {...a11yProps(1)} disabled={isDisMonth}/>
                                <Tab label={t("calendar.form.week_day")} {...a11yProps(2)} disabled={isDisDay}/>
                                <Tab label={t("calendar.form.day")} {...a11yProps(3)} disabled={isDisDay}/>
                                <Tab label={t("calendar.form.hour")} {...a11yProps(4)} />
                            </Tabs>
                        </Grid>

                        <Grid item xs={12}>
                            <TabPanel value={tabIndex} index={0}>
                                {yearsArr.map((year) => (
                                    <Grid item xs={3} key={year}>
                                        <Button
                                            onClick={handleToggle.bind(null, year, except_year, setFieldValue, "except_year")}
                                            fullWidth variant={"contained"}
                                            color={years.includes(year) ? "secondary" : "primary"}
                                        >
                                            {year}
                                        </Button>
                                    </Grid>))}
                            </TabPanel>
                            <TabPanel value={tabIndex} index={1}>
                                {monthsNames.map((month, i) => (
                                    <Grid item xs={4} key={month}>
                                        <Button
                                            onClick={handleToggle.bind(null, i, except_month, setFieldValue, "except_month")}
                                            fullWidth variant={"contained"}
                                            color={months.includes(month) ? "secondary" : "primary"}
                                        >
                                            {month}
                                        </Button>
                                    </Grid>))}
                            </TabPanel>
                            <TabPanel value={tabIndex} index={2}>
                                {weekNames.map((name, i) => {
                                    return (
                                        <Grid item xs={6} key={name}>
                                            <Button
                                                onClick={handleToggle.bind(null, i, except_week_day, setFieldValue, "except_week_day")}
                                                fullWidth variant={"contained"}
                                                color={week_days.includes(name) ? "secondary" : "primary"}
                                            >
                                                {name}
                                            </Button>
                                        </Grid>)
                                })}
                            </TabPanel>
                            <TabPanel value={tabIndex} index={3}>
                                {daysArr.map((day, i) => (
                                    <Grid item xs={3} key={day + "d"}>
                                        <Button
                                            onClick={handleToggle.bind(null, day, except_day, setFieldValue, "except_day")}
                                            fullWidth variant={"contained"}
                                            color={days.includes(day) ? "secondary" : "primary"}
                                        >
                                            {i + 1}
                                        </Button>
                                    </Grid>))}
                            </TabPanel>
                            <TabPanel value={tabIndex} index={4}>
                                {hoursArr.map((hour, i) => (
                                    <Grid item xs={3} key={hour + "h"}>
                                        <Button
                                            onClick={handleToggle.bind(null, hour, except_hour, setFieldValue, "except_hour")}
                                            fullWidth variant={"contained"}
                                            color={hours.includes(hour) ? "secondary" : "primary"}
                                        >
                                            {i}
                                        </Button>
                                    </Grid>))}
                            </TabPanel>
                        </Grid>

                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={selectAll.bind(null, tabIndex, setFieldValue, years, months, week_days, days, hours, monthsNames, weekNames)}
                        variant={"contained"} color={"secondary"}>{t("calendar.form.select_all")}</Button>
                    <Button onClick={() => setOpenDial(false)} variant={"text"}>{t("calendar.form.close")}</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default Except;
