import React from 'react';
import {Checkbox, Grid, GridList, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import {KeyboardDatePicker, TimePicker} from "@material-ui/pickers";
import moment from "moment";
import {useTranslation} from "react-i18next";

const StartEvent = ({
                        start_date,
                        start_time,
                        astro_start,
                        astro_start_offset,
                        is_astro_start,
                        isRecurring,
                        setFieldValue,
                        handleChange
                    }) => {

    const checkOffsetInput = (value) => {
        const length = value.length;

        if (!value.match(/^[-\d]\d*$/)) return value.slice(0, length - 1)

        if (value[0] === "-") return value[1] !== '0' ? value : value.slice(0, length - 1)

        if (value[0] === "0" && length > 1) return value.slice(1)

        return value
    }
    const {t} = useTranslation();
    return (
        <Grid container spacing={2}>

            <Grid item xs={12}>
                <InputLabel>
                    {!isRecurring ? t("calendar.form.date") : t("calendar.form.date")}
                </InputLabel>
                <KeyboardDatePicker
                    name={"start_date"}
                    value={start_date}
                    fullWidth
                    onChange={(date) => setFieldValue("start_date", date)}
                    cancelLabel={t("calendar.form.cancel")}
                    okLabel={t("calendar.form.ok")}
                />
            </Grid>

            <Grid item xs={12}>
                <InputLabel>
                    {!isRecurring ? t("calendar.form.time") : t("calendar.form.time")}
                </InputLabel>
                <Grid container>

                    <Grid item style={{display: "flex", alignItems: "center"}}>
                        <InputLabel form={"is_astro_start"}>
                            {t("calendar.form.astro")}
                        </InputLabel>
                    </Grid>
                    <Grid item>
                        <Checkbox
                            id={"is_astro_start"}
                            color="primary"
                            checked={is_astro_start}
                            name={"is_astro_start"}
                            onChange={handleChange}
                        />
                    </Grid>

                </Grid>
                {!is_astro_start ? (
                    <TimePicker
                        name={"start_time"}
                        value={start_time ? moment(start_time, "HH:mm:ss").format() : new Date()}
                        views={["hours", "minutes"]}
                        ampm={false}
                        fullWidth
                        onChange={(date) => setFieldValue("start_time", date)}
                        cancelLabel={t("calendar.form.cancel")}
                        okLabel={t("calendar.form.ok")}
                    />
                ) : (
                    <>
                        <Grid item xs={12}>
                            <Select
                                name={"astro_start"}
                                value={astro_start}
                                onChange={handleChange}
                                fullWidth
                            >
                                <MenuItem value={"sunrise"}>{t("calendar.form.sunrise")}</MenuItem>
                                <MenuItem value={"sunset"}>{t("calendar.form.sunset")}</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={t("calendar.form.timeOffset")}
                                name={"astro_start_offset"}
                                value={astro_start_offset}
                                fullWidth
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                                onChange={(e) => {
                                    setFieldValue("astro_start_offset", checkOffsetInput(e.target.value))
                                }}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default StartEvent;
