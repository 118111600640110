import {CLOSE_MODAL, GET_EVENTS, OPEN_MODAL, SELECT_EVENT} from "./calendarActions";
import moment from "moment";





const initState = {
    srcEvents: [],
    isModalOpen: false,
    selectedEventId: null,
    selectedComplexEventId: null,
    selectedRange: null
};

export const calendarReducer = (state = initState, action) => {
    const {type, payload} = action;
    switch (type) {
        case OPEN_MODAL: {
            return {
                ...state,
                isModalOpen: payload
            }
        }
        case CLOSE_MODAL: {
            return {
                ...state,
                selectedEventId: null,
                isModalOpen: false
            }
        }
        case GET_EVENTS: {
            // const events = payload.map(expandEvent).flat(1)
            return {
                ...state,
                srcEvents: payload.source,
                events:payload.calendarObjects
            }
        }
        case SELECT_EVENT: {
            return {
                ...state,
                selectedEventId: payload,
                isModalOpen: true
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}
