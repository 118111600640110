import React, {useContext} from "react";
import {addMinutes} from 'date-fns';
import merge from 'lodash/merge';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {useSnackbar} from 'notistack';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    Box,
    Button, DialogContent, DialogTitle,
    Grid,
    IconButton, InputLabel,
    TextField,
    Typography
} from '@material-ui/core';

import {createEvent, deleteEvent, updateEvent} from '../../pages/Calendar/calendarActions';
import {useDispatch, useSelector} from "react-redux";
import {IOSSwitch} from "../options/SetAutoModeSwitcher";

import CalendarCommandPicker from "./CalendarCommandPicker";
import StartEvent from "./StartEvent";
import EndEvent from "./EndEvent";
import Except from "./Except";
import Every from "./Every";
import {CalendarSocket} from "../../context/CalendarConnectionProvider";
import moment from "moment";
import {useTranslation} from "react-i18next";

export const CalendarEventForm = (props) => {
    const {
        event,
        onAddComplete,
        onCancel,
        onDeleteComplete,
        onEditComplete,
        latitude,
        longitude,
        projectUID,
        timezone
    } = props;
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const {calendar} = useContext(CalendarSocket);
    const {t} = useTranslation();
    const handleDelete = () => {
        try {
            calendar.emit("deleteEvent", {eventID: event.id}, ({status, message}) => {
                if(message === "Событие удалено."){
                    message = t("message.removeSuccess")
                }
                enqueueSnackbar(message, {variant: status})
                onDeleteComplete()
            })
        } catch (err) {
            console.error(err);
        }
    };
    const isCreating = !event;

    return (
        <Formik
            initialValues={isCreating ? {
                title: "",
                is_recurring: false,
                is_astro_start: false,
                astro_start_offset: 0,
                astro_start: "sunset",
                astro_end: "sunset",
                start_time: new Date(),
                is_astro_end: false,
                astro_end_offset: 0,
                start_every: [0],
                except_year: [],
                except_month: [],
                except_week_day: [],
                except_day: [],
                except_hour: [],
                end_time: new Date(),
                start_date: new Date(),
                end_date: null,
                commands: []
            } : event}
            validationSchema={Yup
                .object()
                .shape({
                    title: Yup.string().required(t("message.fieldReq"))
                })}

            onSubmit={(values, {resetForm, setErrors, setStatus, setSubmitting}) => {
                const data = {
                    projectUID,
                    latitude, longitude, timezone,
                    ...values,
                    start_time: moment(values.start_time, "HH:mm:ss").format("HH:mm"),
                    start_date: moment(values.start_date).format("YYYY-MM-DD"),
                    end_time: values.end_time ? moment(values.end_time, "HH:mm:ss").format("HH:mm") : null,
                    end_date: values.end_date ? moment(values.end_date).format("YYYY-MM-DD") : null
                };
                const res = ({status, message}) => {
                    if (status === "error") {
                        setStatus({success: false});
                    } else {
                        resetForm();
                        onAddComplete();
                        setStatus({success: true});
                    }
                    setSubmitting(false);
                    if(message === "Эвент успешно добавлен"){
                        message = t("message.addEventSuccess")
                    }
                    enqueueSnackbar(message, {
                        variant: status
                    })
                }

                setSubmitting(true);
                isCreating ? calendar.emit("addEvent", data, res) : calendar.emit("editEvent", event.id, data, res)
            }}
        >
            {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  touched,
                  values
              }) => (
                <form onSubmit={handleSubmit}>
                    <DialogTitle id="scroll-dialog-title"
                                 style={{paddingTop: "calc(env(safe-area-inset-top, 0) + 16px)"}}>
                        <Grid container justifyContent={"space-between"} alignItems={"center"}>
                            <Grid item>
                                <Button
                                    color="primary"
                                    onClick={onCancel}
                                    variant="text"
                                >
                                    {t("calendar.form.cancel")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Typography
                                    align="center"
                                    color="textPrimary"
                                    variant="h5"
                                >
                                    {isCreating
                                        ? t("calendar.form.new")
                                        : t("calendar.form.edit")
                                    }
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Button
                                    color="primary"
                                    disabled={isSubmitting}
                                    sx={{ml: 1}}
                                    type="submit"
                                    variant="text"
                                >
                                    {t("calendar.form.ok")}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    error={Boolean(touched.title && errors.title)}
                                    fullWidth
                                    helperText={touched.title && errors.title}
                                    label={t("calendar.form.title")}
                                    name="title"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.title}
                                    variant="outlined"
                                    autoComplete={"off"}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent={"flex-start"} spacing={1}>
                                    <Grid item>
                                        <IOSSwitch
                                            checked={values.is_recurring}
                                            color="primary"
                                            name="is_recurring"
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item style={{display: "flex", alignItems: "center"}}>
                                        <InputLabel>
                                            {t("calendar.form.repeat")}
                                        </InputLabel>
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid item xs={6}>
                                <StartEvent {...values} setFieldValue={setFieldValue} handleChange={handleChange}/>
                            </Grid>

                            {values.is_recurring && (
                                <>
                                    <Grid item xs={6}>
                                        <EndEvent
                                            {...values}
                                            setFieldValue={setFieldValue}
                                            handleChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Every {...values} setFieldValue={setFieldValue}/>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Except {...values} setFieldValue={setFieldValue}/>
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12} style={{mt: 2}}>
                                <CalendarCommandPicker commands={values.commands} setFieldValue={setFieldValue}/>
                            </Grid>

                        </Grid>

                    </DialogContent>

                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            p: 2
                        }}
                    >
                        {!isCreating && (
                            <IconButton onClick={handleDelete}>
                                <DeleteIcon fontSize="small"/>
                            </IconButton>
                        )}
                    </Box>
                </form>
            )}

        </Formik>

    );
};
