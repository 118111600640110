
import {combineReducers} from "redux";
import {appReducer} from "./appReducer";
import {calendarReducer} from "../pages/Calendar/calendarReducer";
import {programsReducer} from "../pages/Program/programsReducer";
import {themeReducer} from "../theme/themeReducer"




export const rootReducer = combineReducers({
    app: appReducer,
    calendar: calendarReducer,
    programs: programsReducer,
    theme:themeReducer
})
