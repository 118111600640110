import React from "react";
import {withStyles, Switch} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {toggleAutoMode} from "../../store/appActions";
import {useContext} from "react";
import {SocketContext} from "../../context/ConnectionProvider";


export const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        // margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#52d869',
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

export const SetAutoModeSwitcher = () => {
    const dispatch = useDispatch();
    const connection = useContext(SocketContext);
    const isAutoMode = useSelector(({app})=>app.isAutoMode);
    const projectUID = useSelector(({app})=>app.projectUID)
    const handleChange = (e) =>{
        connection.socket.emit(
            "setManualMode",
            {projectUID,status:e.target.checked}
        )
        dispatch(toggleAutoMode(e.target.checked))
    }
    return <IOSSwitch checked={isAutoMode} onChange={handleChange} name="auto mode"/>
}

