export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
export const TOGGLE_SIDE_MENU="TOGGLE_SIDE_MENU";
export const TOGGLE_AUTO_MODE="TOGGLE_AUTO_MODE";

export const getAuth = (payload) =>{
    return {type:AUTH,payload}
}

export const toggleSideMenu = () =>({
    type:TOGGLE_SIDE_MENU
})

export const toggleAutoMode = (payload) =>({
    type:TOGGLE_AUTO_MODE,
    payload
})

export const logout = () =>({
    type:LOGOUT
})
