import React, {useEffect, useRef, useState} from 'react';
import {useTranslation, withTranslation} from "react-i18next";
import {IconButton, ListItem, ListItemIcon, ListItemText, Menu, MenuItem} from "@material-ui/core";
import TranslateIcon from "@material-ui/icons/Translate";
import moment from "moment/moment";

const LanguageButton = () => {
    const {i18n} = useTranslation();
    const [open, setOpen] = useState(false)
    const menuAnchor = useRef(null)
    const handleClick = () => {
        setOpen(true)
    };
    const handleClose = () => {
        setOpen(false)
    };
    const handleChangeLang = (lang) => {
        i18n.changeLanguage(lang, handleClose)
    }

    useEffect(()=>{
        moment.locale(i18n.language)
    },[i18n.language])

    return (
        <>
            <ListItem button
                      size="small"
                      edge="end"
                      color="inherit"
                      id="language-button"
                      aria-controls="language-menu"
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
            >
                <ListItemIcon>
                    <TranslateIcon/>
                </ListItemIcon>
                <ListItemText ref={menuAnchor}>
                    {i18n.language === "uk" ? "UA" : i18n.language.toUpperCase()}
                </ListItemText>
            </ListItem>
            <Menu
                id="language-menu"
                anchorEl={menuAnchor.current}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'language-button',
                }}
            >
                {i18n.languages.map(language => (
                    <MenuItem
                        key={language}
                        onClick={() => handleChangeLang(language)}
                    >
                        {language === "uk" ? "UA" : language.toUpperCase()}
                    </MenuItem>)
                )}
            </Menu>
        </>
    )
        ;
};

export default withTranslation()(LanguageButton);
