import React, {createContext, useCallback, useEffect, useRef, useState} from "react";
import {useSnackbar} from "notistack";
import socketio from "socket.io-client";
import {useDispatch, useSelector} from "react-redux";
import {logout, toggleAutoMode} from "../store/appActions";
import {getPrograms} from "../pages/Program/programActions";
import {getCustomTheme, updateCustomTheme} from "../theme/themeActions";
import {Button, makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";

export const SocketContext = createContext(null);

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 2,
        color: theme.palette.primary.main,
        backgroundColor: "transparent"
    },
}))

export const ConnectionProvider = ({children}) => {
    const dispatch = useDispatch();
    const token = useSelector(({app}) => app.token);
    const projectUID = useSelector(({app}) => app.projectUID);

    const socket = useRef(null);
    const [isConnected, setConnectionStatus] = useState(false);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {t} = useTranslation();
    const getStatusManualMode = useCallback(({status,message,data})=>{
        status !== "success" ? enqueueSnackbar(message, {variant: status}) : data && dispatch(toggleAutoMode(data.status))
    },[dispatch])

    const getThemeHandler = useCallback(({status, message, data}) => {
        status !== "success" ? enqueueSnackbar(message, {variant: status}) : data && dispatch(getCustomTheme(data))
    }, [dispatch]);

    const updateThemeHandler = useCallback((data) => {
        dispatch(updateCustomTheme(data))
    }, [dispatch]);

    const getProgramsHandler = useCallback((data) => {
        dispatch(getPrograms(data))
    }, [dispatch]);

    const updateManualMode = useCallback((data) => {
        dispatch(toggleAutoMode(data))
    }, [dispatch]);

    const snackbarAction = useCallback((key) => (
        <Button onClick={() => {
            closeSnackbar(key)
        }}>
            Ok
        </Button>
    ), []);

    const blockHandler = useCallback(({status, message}) => {
        enqueueSnackbar(message, {
            variant: status,
            autoHideDuration: null,
            action: snackbarAction
        });
        dispatch(logout())
    }, []);


    useEffect(() => {
        if (token && projectUID) {
            const connection = socketio(process.env.NODE_ENV === "development"?"https://client.expolightcontrols.net/users":"/users", {
                path: "/socket",
                transports: ['websocket'],
                rememberUpgrade: true,
                auth: {
                    token
                }
            });
            connection.on("connect", () => {
                socket.current = connection;
                connection.emit("joinRoom", {room: projectUID})
                connection.emit("getManualMode",{projectUID},getStatusManualMode)
                setConnectionStatus(true);
                enqueueSnackbar(t("message.serverConnected"), {variant: "success"})
            });

            connection.on("disconnect", () => {
                enqueueSnackbar(t("message.serverDisconnected"), {variant: "warning"});
                setConnectionStatus(false)
            })

            connection.on("connect_error", err => {
                dispatch(logout());
                connection.close();
                enqueueSnackbar(err.message, {variant: "error"})
            })
        }
    }, [token, projectUID]);

    useEffect(() => {
        if (isConnected && socket.current) {
            socket.current.on("block", blockHandler);
            socket.current.on("programsUpdate", getProgramsHandler);
            socket.current.on("updateTheme", updateThemeHandler);
            socket.current.on("manualModeChange", updateManualMode);
            socket.current.emit("getTheme", {projectUID}, getThemeHandler)
            return () => {
                socket.current.off("block", blockHandler);
                socket.current.off("programsUpdate", getProgramsHandler)
                socket.current.off("updateTheme", updateThemeHandler)
                socket.current.off("manualModeChange", updateManualMode);
            }
        }
    }, [isConnected]);

    return (
        <SocketContext.Provider value={{socket: socket.current}}>
            {children}
        </SocketContext.Provider>
    );
};
