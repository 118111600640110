import {responsiveFontSizes, createTheme} from "@material-ui/core/styles";

export const theme = responsiveFontSizes(createTheme({
    palette: {
        type: "dark",
        background: {
            default: window.localStorage.getItem("background")||"#393e64",
            paper: window.localStorage.getItem("menuBackground")||"#836ff2"
        },
        primary:{
            main: '#fafafa'
        },
        secondary: {
            main: "#DC194C"
        }
    },
    typography: {
        fontFamily: "GraphikLCG-Regular"
    }
}))
