import React, {useCallback} from 'react';
import {Button, Checkbox, Grid, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import {KeyboardDatePicker, TimePicker} from "@material-ui/pickers";
import moment from "moment";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
function EndEvent({astro_start_offset, astro_start, start_time, start_date, end_date, is_astro_end, astro_end, handleChange, astro_end_offset, end_time, setFieldValue}) {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {t} = useTranslation();
    const checkOffsetInput = (value) => {
        const length = value.length;

        if (!value.match(/^[-\d]\d*$/)) return value.slice(0, length - 1)

        if (value[0] === "-") return value[1] !== '0' ? value : value.slice(0, length - 1)

        if (value[0] === "0" && length > 1) return value.slice(1)

        return value
    }
    const snackbarAction = useCallback((key) => (
        <Button onClick={() => {
            closeSnackbar(key)
        }}>
            Ok
        </Button>
    ), []);
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <InputLabel>
                    {t("calendar.form.endDate")}
                </InputLabel>
                <KeyboardDatePicker
                    name={"end_date"}
                    minDate={start_date}
                    value={end_date}
                    clearable
                    fullWidth
                    onChange={(date) => setFieldValue("end_date", date)}
                    cancelLabel={t("calendar.form.cancel")}
                    okLabel={t("calendar.form.ok")}
                    clearLabel={t("calendar.form.clear")}
                />
            </Grid>
            <Grid item xs={12}>
                <InputLabel>
                    {t("calendar.form.endTime")}
                </InputLabel>
                <Grid container>

                    <Grid style={{display: "flex", alignItems: "center"}}>
                        <InputLabel form={"is_astro_end"}>
                            {t("calendar.form.astro")}
                        </InputLabel>
                    </Grid>

                    <Grid>
                        <Checkbox
                            id={"is_astro_end"}
                            color="primary"
                            checked={is_astro_end}
                            name={"is_astro_end"}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                {!is_astro_end ? (
                    <TimePicker
                        name={"end_time"}
                        value={end_time ? moment(end_time, "HH:mm:ss").toDate() : new Date()}
                        views={["hours", "minutes"]}
                        ampm={false}
                        fullWidth
                        cancelLabel={t("calendar.form.cancel")}
                        okLabel={t("calendar.form.ok")}
                        onChange={(date) => {
                            if(moment(start_date).isSame(moment(end_date))){
                                if(moment(date).isSameOrAfter(start_time)){
                                    return  setFieldValue("end_time", date)
                                }
                                setFieldValue("end_time", start_time);
                                enqueueSnackbar(t("message.eventEndAfter"),{
                                    variant:"warning",
                                    autoHideDuration: null,
                                    action: snackbarAction
                                });
                                return
                            }
                            setFieldValue("end_time", date)
                        }}
                    />
                ) : (
                    <>
                        <Grid item xs={12}>
                            <Select
                                name={"astro_end"}
                                value={astro_end}
                                onChange={(e)=>{
                                    if(moment(start_date).isSame(moment(end_date))){
                                        if(astro_start === "sunset" && e.target.value === "sunrise"){
                                            enqueueSnackbar(t("message.eventSunsetSunRise"),{
                                                variant:"warning",
                                                autoHideDuration: null,
                                                action: snackbarAction
                                            });
                                            return
                                        }
                                    }
                                    handleChange(e)
                                }}
                                fullWidth
                            >
                                <MenuItem value={"sunrise"}>{t("calendar.form.sunrise")}</MenuItem>
                                <MenuItem value={"sunset"}>{t("calendar.form.sunset")}</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={t("calendar.form.timeOffset")}
                                name={"astro_end_offset"}
                                value={astro_end_offset}
                                inputProps={{
                                    autoComplete: 'off',
                                }}
                                fullWidth
                                onChange={(e) => {

                                    // const value = e.target.value !== "-" ? +e.target.value : e.target.value;
                                    // if(moment(start_date).isSame(moment(end_date))){
                                    //     if(value - (+astro_start_offset) < 0) {
                                    //         enqueueSnackbar("Внимание! Накладка смещения. Событие не выпонится.",{
                                    //             variant:"warning",
                                    //             autoHideDuration: null,
                                    //             action: snackbarAction
                                    //         });
                                    //         return
                                    //     }
                                    // }

                                    setFieldValue("astro_end_offset", checkOffsetInput(e.target.value))
                                }}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>
    );
}

export default EndEvent;
