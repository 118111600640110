export const CLOSE_MODAL = "CLOSE_MODAL";
export const OPEN_MODAL = "OPEN_MODAL";
export const GET_EVENTS = "GET_EVENTS";
export const SELECT_EVENT = "SELECT_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const SELECT_RANGE = "UPDATE_RANGE";
export const CREATE_EVENT = "CREATE_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";


export function createEvent() {
}

export function deleteEvent() {

}

export function closeModal() {
    return{
        type:CLOSE_MODAL
    }
}

export function getEvents(events){
    return {
        type:GET_EVENTS,
        payload:events
    }
}

export function openModal() {
    return{
        type:OPEN_MODAL,
        payload:true
    }
}

export function selectEvent(eventID) {
    return {
        type:SELECT_EVENT,
        payload:eventID
    }
}

export function selectRange() {
}

export function updateEvent() {
}
