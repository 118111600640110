import React, {createContext, useCallback, useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {Backdrop, Button, CircularProgress, makeStyles} from "@material-ui/core";
import socketio from "socket.io-client";

import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: 2,
        color: theme.palette.primary.main,
        backgroundColor: "transparent"
    },
}))

export const CalendarSocket = createContext(null);

export const CalendarConnectionProvider = ({children}) => {
    const classes = useStyles();
    const history = useHistory();
    const token = useSelector(({app}) => app.token);
    const projectUID = useSelector(({app}) => app.projectUID);

    const socket = useRef(null);
    const [isConnected, setConnectionStatus] = useState(false);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const {t} = useTranslation();
    const snackbarAction = useCallback((key) => (
        <Button onClick={() => {
            closeSnackbar(key)
        }}>
            Ok
        </Button>
    ), []);

    useEffect(() => {
        if (token && projectUID) {

            const connection = socketio(process.env.NODE_ENV === "development"?"https://client.expolightcontrols.net":"/", {
                path: "/calendar",
                transports: ['websocket'],
                rememberUpgrade: true,
                auth: {
                    token
                }
            });
            connection.on("connect", () => {
                socket.current = connection;
                connection.emit("joinRoom", {room: projectUID})
                setConnectionStatus(true);
            });

            connection.on("disconnect", () => {
                setConnectionStatus(false)
            })

            connection.on("connect_error", err => {
                console.dir(err)
                history.push("/programs");
                connection.disconnect()
                enqueueSnackbar(t("message.calendarConError"), {variant: "error"})
            })
        }
    }, [token, projectUID])
    useEffect(() => {
        if(isConnected){
            socket.current.emit("joinRoom",{room:projectUID});
        }
    }, [isConnected])
    useEffect(()=>()=>{
      if(socket.current) socket.current.close();
    },[])
    return (
        <CalendarSocket.Provider value={{calendar: socket.current}}>
            {isConnected ? children : (
                <Backdrop className={classes.backdrop} open={!isConnected}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            )}
        </CalendarSocket.Provider>
    )
}
