import React, {useContext, useEffect, useRef, useState} from 'react';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import {alpha, Button, Dialog, useMediaQuery, makeStyles, Grid, Typography, Slide} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {
    closeModal,
    getEvents,
    openModal,
    selectEvent
} from "./calendarActions";
import {CalendarToolbar} from "../../coomponents/calendar/CalendarToolbar";
import {CalendarEventForm} from "../../coomponents/calendar/CalendarEventForm";
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import ukLocale from '@fullcalendar/core/locales/uk';
import enLocale from '@fullcalendar/core/locales/en-gb';
import AddIcon from '@material-ui/icons/Add';
import moment from "moment";
import momentTz from "moment-timezone";
import {getSunrise, getSunset} from "sunrise-sunset-js";
import {CalendarSocket} from "../../context/CalendarConnectionProvider";
import {useSnackbar} from "notistack";
import {useTranslation, withTranslation} from "react-i18next";

const secInDay = 60 * 60 * 24;

const useStyle = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        '& .fc-license-message': {
            display: 'none'
        },
        '& .fc .fc-toolbar.fc-header-toolbar': {
            display: "none"
        },
        '& .fc': {
            '--fc-bg-event-opacity': 1,
            '--fc-border-color': theme.palette.divider,
            '--fc-daygrid-event-dot-width': '10px',
            '--fc-event-text-color': theme.palette.text.primary,
            '--fc-list-event-hover-bg-color': theme.palette.background.default,
            '--fc-neutral-bg-color': theme.palette.background.default,
            '--fc-page-bg-color': theme.palette.background.default,
            '--fc-today-bg-color': alpha(theme.palette.primary.main, 0.25),
            color: theme.palette.text.primary,
            fontFamily: theme.typography.fontFamily
        },
        '& .fc .fc-col-header-cell-cushion': {
            paddingBottom: '10px',
            paddingTop: '10px'
        },
        '& .fc .fc-day-other .fc-daygrid-day-top': {
            color: theme.palette.text.secondary
        },
        '& .fc-daygrid-day-events': {
            textAlign: "center",
            lineHeight: "2em"
        }
    }

}))

const selectedEventSelector = (state) => {
    const {srcEvents, selectedEventId} = state.calendar;
    if (selectedEventId) {
        return srcEvents.find((_event) => _event.id === selectedEventId);
    }
    return null;
};


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const Calendar = () => {
    const classes = useStyle();
    const {calendar} = useContext(CalendarSocket);
    const {enqueueSnackbar} = useSnackbar();
    const mobileDevice = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const latitude = useSelector(({app}) => app.latitude);
    const longitude = useSelector(({app}) => app.longitude);
    const projectUID = useSelector(({app}) => app.projectUID);
    const timezone = useSelector(({app}) => app.timezone);
    const {i18n,t} = useTranslation();


    useEffect(()=>{
        moment.locale(i18n.language)
    },[])


    const getEventsHandler = (callback = function () {
    }) => {
        const calendarR = calendarRef.current
        if (!calendar) return
        const calendarApi = calendarR.getApi();
        const from = calendarApi.view.currentStart;
        const to = calendarApi.view.currentEnd;
        calendar.emit("getEvents", {projectUID, from, to}, (res) => {
            res.status === 'success' ? dispatch(getEvents(res.data)) : enqueueSnackbar(res.message, {variant: res.status});
            callback()
        })
    }

    const [view, setView] = useState(mobileDevice
        ? 'listWeek'
        : 'dayGridMonth');

    const [date, setDate] = useState(new Date());
    const events = useSelector(({calendar}) => calendar.events);
    const isModalOpen = useSelector(({calendar}) => calendar.isModalOpen);
    const selectedEvent = useSelector(selectedEventSelector);
    const accessLevel = useSelector(({app}) => app.accessLevel)
    const dispatch = useDispatch();
    const calendarRef = useRef(null);

    useEffect(() => {
        // getEventsHandler()
        return () => {
            dispatch(closeModal())
            // calendar.disconnect(true)
        }
    }, []);

    useEffect(() => {
        const indicatorContainers = document.querySelectorAll(".fc-timegrid-now-indicator-container");
        const timeGridHeight = indicatorContainers[1] && indicatorContainers[1].clientHeight;
        const timeGridPositionPercent = timeGridHeight / secInDay;

        const dates = [...indicatorContainers].slice(1).map(node => new Date(node.parentElement.parentElement.dataset.date));

        const sunrises = dates.map(date => {
            const fullTime = momentTz(getSunrise(latitude, longitude, date)).tz(timezone);

            return ((fullTime.get("hour") * 60 * 60) + (fullTime.get("minutes") * 60) + fullTime.get("seconds")) * timeGridPositionPercent;
        });

        const sunsets = dates.map(date => {
            const fullTime = momentTz(getSunset(latitude, longitude, date)).tz(timezone)
            return ((fullTime.get("hour") * 60 * 60) + (fullTime.get("minutes") * 60) + fullTime.get("seconds")) * timeGridPositionPercent;
        });

        if (indicatorContainers) {

            indicatorContainers.forEach((el, index, containers) => {
                if (index === 0 && containers.length <= 2) {
                    const arrowSunrise = document.createElement("div");
                    const arrowSunset = document.createElement("div");
                    arrowSunrise.classList.add("sunrise-indicator-arrow");
                    arrowSunrise.style.top = `${sunrises[0]}px`;
                    arrowSunset.classList.add("sunset-indicator-arrow");
                    arrowSunset.style.top = `${sunsets[0]}px`;
                    el.appendChild(arrowSunrise);
                    el.appendChild(arrowSunset);
                } else if (index > 0) {
                    const lineSunrise = document.createElement("div");
                    const lineSunset = document.createElement("div");
                    lineSunrise.classList.add("sunrise-indicator-line");
                    lineSunrise.style.top = `${sunrises[index - 1]}px`;
                    lineSunset.classList.add("sunset-indicator-line");
                    lineSunset.style.top = `${sunsets[index - 1]}px`;
                    el.appendChild(lineSunrise);
                    el.appendChild(lineSunset);
                }
            })
        }
        return () => {
            document.querySelectorAll([".sunrise-indicator-arrow", ".sunrise-indicator-line"]).forEach(node => {
                node.remove()
            })
        }
    }, [view, date])

    useEffect(() => {
        getEventsHandler()
    }, [view, date])

    const handleDateToday = () => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();

            calendarApi.today();
            setDate(calendarApi.getDate());
        }
    };

    const handleViewChange = (newView) => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();

            calendarApi.changeView(newView);
            setView(newView);
        }
    };

    const handleDatePrev = () => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();

            calendarApi.prev();
            setDate(calendarApi.getDate());
        }
    };

    const handleDateNext = () => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.next();
            setDate(calendarApi.getDate());
        }
    };

    const handleAddClick = () => {
        dispatch(openModal());
    };

    const handleEventSelect = (arg) => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            console.dir(calendarEl.getApi())
        }
        dispatch(selectEvent(arg.event.groupId));
    };

    const handleModalClose = () => {
        dispatch(closeModal());
    };

    const handleCreateEventSuccess = () => {
        getEventsHandler(handleModalClose)
    };
    const handleDelete = () => {
        getEventsHandler(handleModalClose)
    }
    const handleSelectDay = (date) => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.gotoDate(date);
            calendarApi.changeView("timeGridDay");
            setView("timeGridDay");
            setDate(date);
        }
    };

    useEffect(() => {
        const calendarEl = calendarRef.current;
        if (view === "timeGridDay" && calendarEl) {
            const calendarApi = calendarEl.getApi();
            calendarApi.scrollToTime()
        }
    }, [view])

    return (

        <Grid style={{height: '100%'}} container direction={"column"} spacing={1}>
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={i18n.language}>

                <Grid item container justifyContent="space-between" alignItems={"center"}>
                    <Grid item>
                        <CalendarToolbar
                            date={date}
                            onDateNext={handleDateNext}
                            onDatePrev={handleDatePrev}
                            onDateToday={handleDateToday}
                            onViewChange={handleViewChange}
                            view={view}
                        />
                    </Grid>
                    {accessLevel === 3 ? <Grid item>
                        <Button
                            color="secondary"
                            onClick={handleAddClick}
                            sx={{m: 1}}
                            variant="contained"
                            startIcon={<AddIcon/>}
                            size={"small"}
                        >
                            {t("calendar.interface.event")}
                        </Button>
                    </Grid> : null}
                </Grid>
                <Grid item container alignItems="center" justifyContent="center">
                    <Typography
                        color="textPrimary"
                        variant={"h5"}
                    >
                        {view === 'timeGridDay' ? moment(date,null,i18n.language).format('MMMM D, y') : moment(date,null,i18n.language).format('MMMM y')}
                    </Typography>
                </Grid>
                <Grid item container style={{flexGrow: 1}}>
                    <Grid item
                          className={classes.root}
                    >
                        <FullCalendar
                            ref={calendarRef}
                            locale={i18n.language === "uk" ? ukLocale : enLocale}
                            eventClick={accessLevel === 3 ? handleEventSelect : () => {
                            }}
                            plugins={[
                                dayGridPlugin,
                                timeGridPlugin,
                                timelinePlugin,
                                listPlugin
                            ]}
                            eventMaxStack={3}
                            dayMaxEvents={false}
                            dayMaxEventRows={0}
                            nowIndicator
                            now={momentTz.tz(timezone).format()}
                            timeZone={timezone}
                            editable
                            selectable
                            events={events}
                            initialDate={date}
                            initialView={'dayGridMonth'}
                            headerToolbar='none'
                            handleWindowResize={true}
                            navLinks
                            navLinkDayClick={(data) => handleSelectDay(data)}
                            height="70vh"
                            contentHeight="100%"
                            allDaySlot={false}
                            displayEventEnd={false}
                            displayEventTime={"00"}
                            moreLinkText={function (n) {
                                return '+ ' + n
                            }}
                        />

                    </Grid>
                </Grid>


                <Dialog
                    fullScreen
                    onClose={handleModalClose}
                    open={isModalOpen}
                    TransitionComponent={Transition}
                    scroll={"paper"}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    {/* Dialog renders its body even if not open */}
                    {isModalOpen && (
                        <CalendarEventForm
                            event={selectedEvent}
                            onAddComplete={handleCreateEventSuccess}
                            onCancel={handleModalClose}
                            onDeleteComplete={handleDelete}
                            onEditComplete={handleModalClose}
                            latitude={latitude}
                            longitude={longitude}
                            projectUID={projectUID}
                            timezone={timezone}
                        />
                    )}
                </Dialog>
            </MuiPickersUtilsProvider>
        </Grid>

    );
};

export default withTranslation()(Calendar);
